<template>

    <div id="epa_sidebar" :class="stickyClass">
        <ul class="menu" ref="menuElement">
            <li v-for="item in menuItems" :class="`menu_item ${route().current(item.route) ? 'active':''}`" @click.prevent="router.visit(route(item.route))">
                <EpaIcon :icon="item.icon"/>
                {{ $t(item.label) }}
            </li>
        </ul>

        <ul class="bottommenu menu">
            <li class="menu_item" @click.prevent="router.visit(route('nova.login'))" >
                <EpaIcon icon="admin"/>
                {{ $t('Admin') }}
            </li>
        </ul>
    </div>

</template>

<script setup>
import { router } from '@inertiajs/vue3';
import {ref, onMounted, onUpdated, onUnmounted} from 'vue';
import EpaIcon from "../UI/EpaIcon.vue";

const menuItems = [
    {
        'route': 'dashboard',
        'label': 'Dashboard',
        'icon': 'dashboard'
    },
    {
        'route': 'survey.index',
        'label': 'Surveys',
        'icon': 'surveys'
    },
    {
        'route': 'results',
        'label': 'Results',
        'icon': 'results'
    },
    {
        'route': 'logout',
        'label': 'Logout',
        'icon': 'logout'
    },
];


const menuElement = ref(null);
const stickyClass = ref('');

const checkHeight = () => {
    if (menuElement.value)
    {
        const menuHeight = menuElement.value.offsetHeight;
        const windowHeight = window.innerHeight - 100;

        if( windowHeight > menuHeight )
        {
            stickyClass.value = '';
            return;
        }
    }
    stickyClass.value = 'not_sticky';
};

onMounted(() => {
    checkHeight();
    window.addEventListener('resize', checkHeight);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkHeight);
});

onUpdated(() => {
    checkHeight();
});

</script>

<style scoped lang="scss">

#epa_sidebar {
    background-color: $sidebar-background-color;
    color: $sidebar-text-color;
    display: grid;

    .menu {
        display: grid;
        gap: 0;
        height: fit-content;
        padding: 0;
        margin: 0;
        position: sticky;
        top: 0;

        .menu_item {
            border-left: 0.2rem solid transparent;
            padding: 3rem;
            transition: 300ms;
            width: 100%;
            list-style: none;
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;

            svg path {
                fill: #fff;
            }

            &.active,
            &:hover {
                border-left: 0.2rem solid $active-menu-item-color;
                background-color: $sidebar-background-color-hover;

                svg {
                    color: $accent-color;
                }
            }
        }
    }

    .bottommenu {
        margin-top: auto;
        top: auto;
        bottom: 0;
        background-color: $sidebar-background-color;
    }

    &.not_sticky {

        .menu:not(.bottommenu) {
            position: static;
        }
    }
}

</style>
