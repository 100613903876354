<template>

    <component
        :is="htmlElement()"
        :href="href"
        :class="['epa_button', props.class]"
        :type="type"
        :disabled="props.disabled"
        :target="props.target"
        :rel="props.rel"
    >

        <EpaIcon v-if="props.bicon" :icon="props.bicon"/>
        <template v-else></template>

        <span><slot></slot></span>

        <EpaIcon v-if="props.loading && props.disabled" icon="loading"/>
        <EpaIcon v-else-if="props.icon" :icon="props.icon"/>
        <template v-else></template>

    </component>

</template>

<script setup>

import EpaIcon from "./EpaIcon.vue";

const props = defineProps([
    'icon',
    'bicon',
    'class',
    'type',
    'disabled',
    'loading',
    'href',
    'target',
    'rel'
]);

const htmlElement = () => {
    return (  props.href ? 'a' : 'button' );
}

</script>

<style scoped lang="scss">

.epa_button {
    padding: 1rem 5rem;
    border-radius: $button-border-radius;
    background-color: $button-background-color;
    display: flex;
    align-items: center;
    gap: $gap_xs;
    outline: 0;
    border: 0;
    color: $button-text-color;
    cursor: pointer;
    transition: 300ms;
    width: fit-content;
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;

    .active,
    &:hover {
        background-color: $button-background-color-hover;
    }

    > span {
        padding-top: 0.2rem; /* fix center font Axiforma */
    }

    svg {
        width: 2.2rem;
        height: auto;

        &.loading {
            animation-name: buttonloading;
            animation-duration: 0.5s;
            animation-iteration-count:infinite;
            animation-timing-function: ease-in-out;
            animation-fill-mode: both;
            animation-delay: 0s;
        }
    }

    &.form {
        width: 100%;
        max-width: 30rem;
        margin: 0 auto;
        justify-content: center;
    }

    &.full {
        width: 100%;
    }

    &.center {
        justify-content: center;
    }

    &.small {
        font-size: 1.6rem;
    }

    &:not(.link)
    {
        &.white {
            color: $text-color;
            background-color: $color_white;
        }

        &.primary {
            color: $primary-color;
            background-color: $color_white;
        }
    }

    &.link {
        padding: 0;
        border-radius: 0;
        text-decoration: none;
        position: relative;
        background-color: transparent;
        color: inherit;
        margin-top: -0.2rem; /* fix center font Axiforma */

        > span {
            background: linear-gradient(currentColor, currentColor) bottom / 0 0.2rem no-repeat;
            background-position: left bottom;
            transition: 300ms;
            color: inherit;
        }

        .active,
        &:hover {
            > span {
                background-size: 100% 0.2rem;
                opacity: 1;
            }
        }

        &.primary {
            color: $primary-color;
        }

        &.return svg:first-child {
            transform: rotate(180deg);
        }
    }

    &.clean {
        padding: 0;
        border-radius: 0;
        text-decoration: none;
        position: relative;
        background-color: transparent;
        color: inherit;
        margin-top: -0.2rem; /* fix center font Axiforma */
        cursor: inherit;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

@keyframes buttonloading { 0%{ transform: rotate(0deg); } 100%{ transform: rotate(360deg);} }

</style>
